<script>
import ClipboardMixin from '@/mixins/clipboard.mixin';
import BtcIcon from '@/components/common/currency-icons/btc';
import EthIcon from '@/components/common/currency-icons/eth';
import CurrencyIcon from '@/components/common/currency-icons/currency';
import { CHART_OPTIONS } from '@/constants/apexChartSettings';
import { mapState } from 'vuex';
export default {
  name: 'account-grid',
  components: {
    BtcIcon,
    EthIcon,
    CurrencyIcon,
  },
  mixins: [ClipboardMixin],
  props: {
    account: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      chartData: [],
      loading: {
        chartData: false,
      },
    };
  },
  computed: {
    ...mapState('auth', ['visibleBalance']),
    chart() {
      return {
        series: [
          {
            name: this.account.currency.key,
            data: this.chartData,
          },
        ],
        chartOptions: CHART_OPTIONS,
      };
    },
  },
  methods: {
    openAccount() {
      this.$router.push({ name: 'accounts-show', params: { id: this.account.id } });
    },
    fetchChartData() {
      this.loading.chartData = true;
      this.axios
        .get(`accounts/${this.account.id}/statistics/balance`)
        .then(({ data }) => {
          this.chartData = data;
        })
        .finally(() => {
          this.loading.chartData = false;
        });
    },
  },
  mounted() {
    this.fetchChartData();
  },
};
</script>

<template>
  <div class="card mini-stats-wid cursor-pointer" @click="openAccount">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="d-flex">
            <btc-icon v-if="account.currency.key === 'BTC'" class="me-4" />
            <eth-icon v-else-if="account.currency.key === 'ETH'" class="me-4" />
            <currency-icon v-else :symbol="account.currency?.symbol || account.currency?.key" class="me-4" />

            <div>
              <h5 class="font-size-20 mb-0">
                <template v-if="visibleBalance">{{ account.amount.toFixed(2) }}</template
                ><template v-else>***</template> {{ account.currency.key }}
              </h5>
              <p class="mb-0 text-muted">{{ account.name }} {{ account.currency.key }}</p>
            </div>

            <b-spinner v-if="loading.chartData" class="ms-auto" small></b-spinner>
          </div>
        </div>

        <div class="col-xxl-6 offset-xxl-6">
          <div>
            <apexchart
              class="apex-charts"
              height="40"
              type="area"
              dir="ltr"
              :series="chart.series"
              :options="chart.chartOptions"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card-body border-top py-3">
      <p class="mb-0 d-flex align-items-center">
        <span v-if="account.is_demo" class="badge badge-soft-primary me-3 px-3">{{ $t('Transaction.Demo') }}</span>
        <span v-else class="badge badge-soft-success me-3 px-3">{{ $t('Transaction.Real') }}</span>
        <span class="text-muted">
          <i class="bx bx-calendar me-1"></i>
          {{ $moment(account.created_at).format('DD MMM, YY') }}
        </span>
        <span class="text-muted ms-auto cursor-pointer" @click.stop="copyToClipboard(account.number)">
          <i class="bx bx-copy"></i>
          <span class="hiddeners ms-1">{{ account.number }}</span>
        </span>
      </p>
    </div>
  </div>
</template>
