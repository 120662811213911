<script>
import BtcIcon from '@/components/common/currency-icons/btc';
import EthIcon from '@/components/common/currency-icons/eth';
import CurrencyIcon from '@/components/common/currency-icons/currency';
import ClipboardMixin from '@/mixins/clipboard.mixin';
import { CHART_OPTIONS } from '@/constants/apexChartSettings';
import { mapState } from 'vuex';

export default {
  name: 'account-list',
  components: {
    BtcIcon,
    EthIcon,
    CurrencyIcon,
  },
  mixins: [ClipboardMixin],
  props: {
    account: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      chartData: [],
      loading: {
        chartData: false,
      },
    };
  },
  computed: {
    ...mapState('auth', ['visibleBalance']),

    chart() {
      return {
        series: [
          {
            name: this.account.currency.key,
            data: this.chartData,
          },
        ],
        chartOptions: CHART_OPTIONS,
      };
    },
  },
  methods: {
    fetchChartData() {
      this.loading.chartData = true;
      this.axios
        .get(`accounts/${this.account.id}/statistics/balance`)
        .then(({ data }) => {
          this.chartData = data;
        })
        .finally(() => {
          this.loading.chartData = false;
        });
    },
  },
  mounted() {
    this.fetchChartData();
  },
};
</script>

<template>
  <tr>
    <td class="ps-5">
      <btc-icon v-if="account.currency.key === 'BTC'" :size="36" class="me-4" />
      <eth-icon v-else-if="account.currency.key === 'ETH'" :size="36" class="me-4" />
      <currency-icon v-else :symbol="account.currency?.symbol || account.currency?.key" :size="36" class="me-4" />
    </td>
    <td>
      <h5 class="font-size-16 mb-1 text-nowrap">
        <template v-if="visibleBalance">{{ account.amount.toFixed(2) }}</template
        ><template v-else>***</template> {{ account.currency.key }}
      </h5>
      <p class="mb-0 text-muted text-nowrap">{{ account.name }} {{ account.currency.key }}</p>
    </td>
    <td>
      <span class="text-muted ms-auto cursor-pointer text-nowrap" @click="copyToClipboard(account.number)">
        <i class="bx bx-copy"></i>
        <span class="hiddeners ms-1">{{ account.number }}</span>
      </span>
    </td>
    <td>
      <span class="text-muted text-nowrap">
        <i class="bx bx-calendar me-1"></i>
        {{ $moment(account.created_at).format('DD MMM, YY') }}
      </span>
    </td>
    <td>
      <span v-if="account.is_demo" class="badge badge-soft-primary me-3 px-3"> {{ $t('Transaction.Demo') }} </span>
      <span v-else class="badge badge-soft-success me-3 px-3"> {{ $t('Transaction.Real') }} </span>
    </td>
    <td>
      <div v-if="loading.chartData" class="d-flex justify-content-center">
        <b-spinner small></b-spinner>
      </div>
      <apexchart
        v-else
        class="apex-charts"
        height="40"
        type="area"
        dir="ltr"
        :series="chart.series"
        :options="chart.chartOptions"
      />
    </td>
    <td>
      <b-dropdown
        class="ms-auto"
        toggle-tag="a"
        menu-class="dropdown-menu-end"
        toggle-class="font-size-16 p-0"
        variant="white"
        right
      >
        <template #button-content>
          <i class="mdi mdi-dots-horizontal"></i>
        </template>
        <b-dropdown-item :to="{ name: 'accounts-show', params: { id: account.id } }">{{
          $t('Common.Open')
        }}</b-dropdown-item>
        <!-- <b-dropdown-item href="#">Another action</b-dropdown-item>
        <b-dropdown-item href="#">Something else here</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item href="#">Separated link</b-dropdown-item> -->
      </b-dropdown>
    </td>
  </tr>
</template>

<style scoped lang="scss">
tr {
  background: #ffffff;
  box-shadow: 0 0 8px rgba(18, 38, 63, 0.06);

  td:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  td:last-child {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
}

.apex-charts {
  max-width: 140px;
}

::v-deep(.dropdown-toggle) {
  border: none;
}
</style>
