btc.vue<script>
export default {
  name: "eth",
  props: {
    size: {
      type: Number,
      default: 56,
    },
  },
}
</script>

<template>
  <div :style="{minWidth: `${size}px`, width: `${size}px`, height: `${size}px`}">
    <span class="avatar-title rounded-circle bg-soft bg-primary text-dark" :style="{fontSize: `${size / 2}px`}">
      <i class="mdi mdi-ethereum"></i>
    </span>
  </div>
</template>
