<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <div class="page-title-box__title flex-wrap">
          <h4 class="mb-0 font-size-18 w-100 w-sm-auto mb-3 mb-sm-0">{{ title }}</h4>

          <div class="d-sm-none w-100 w-sm-auto">
            <slot name="right"></slot>
          </div>
        </div>

        <div class="page-title-center">
          <slot name="center"></slot>
        </div>

        <div class="page-title-right d-none d-sm-block">
          <!-- <b-breadcrumb :items="items" class="m-0"></b-breadcrumb> -->
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>

<style scoped lang="scss">
@media (max-width: 576px) {
  .page-title-box {
    flex-direction: column;
    align-items: flex-start;

    &__title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
  }
}
</style>
