<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import AccountGrid from '@/components/pages/accounts/account-grid';
import AccountList from '@/components/pages/accounts/account-list';
import { mapState } from 'vuex';

export default {
  components: {
    Layout,
    PageHeader,
    AccountGrid,
    AccountList,
  },
  data() {
    return {
      title: this.$t('Accounts.Title'),
      view: localStorage.getItem('accountsView') || 'grid',
    };
  },
  computed: {
    ...mapState('accounts', { loadingAccounts: (state) => state.loading, accounts: (state) => state.accounts }),
  },
  methods: {
    setView(view) {
      localStorage.setItem('accountsView', view);
      this.view = view;
    },
  },
  metaInfo() {
    return {
      title: this.$t('Accounts.Title'),
    };
  },
};
</script>

<template>
  <b-overlay :show="loadingAccounts.accounts" spinner-variant="primary" rounded="sm">
    <Layout>
      <PageHeader :title="title">
        <template v-slot:right>
          <div class="d-flex justify-content-between w-100" style="width: 100%">
            <router-link
              v-if="accounts.length"
              type="button"
              class="btn btn-success fw-bold me-3"
              :to="{ name: 'open-account' }"
            >
              {{ $t('Accounts.CreateAccount') }}
            </router-link>
            <div class="text-muted">
              {{ $t('Common.View') }}:
              <button
                type="button"
                class="btn btn-header-action"
                :class="{ active: view === 'list' }"
                @click="setView('list')"
              >
                <i class="bx bx-list-ul font-size-18"></i>
              </button>

              <button
                type="button"
                class="btn btn-header-action"
                :class="{ active: view === 'grid' }"
                @click="setView('grid')"
              >
                <i class="bx bx-grid-alt font-size-18"></i>
              </button>
            </div>
          </div>
        </template>
      </PageHeader>

      <div v-if="view === 'grid'" class="row">
        <div v-for="account in accounts" :key="account.id" class="col-sm-6 col-xl-4">
          <account-grid :account="account" />
        </div>
      </div>

      <div v-if="view === 'list'" class="row">
        <div class="col-12">
          <div class="accounts-page__table-container">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>{{ $t('Accounts.Currency') }}</th>
                  <th>{{ $t('Accounts.Account') }}</th>
                  <th>{{ $t('Accounts.Id') }}</th>
                  <th>{{ $t('Accounts.CreateDate') }}</th>
                  <th>{{ $t('Accounts.Type') }}</th>
                  <th>{{ $t('Accounts.Progress') }}</th>
                  <th>{{ $t('Accounts.Action') }}</th>
                </tr>
              </thead>
              <tbody>
                <account-list v-for="account in accounts" :key="account.id" :account="account" />
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="!accounts.length" class="accounts-page__no-accounts">
        <p>{{ $t('Accounts.NoAccounts') }}</p>
        <router-link type="button" class="btn btn-success fw-bold" :to="{ name: 'open-account' }">
          {{ $t('Accounts.CreateAccount') }}
        </router-link>
      </div>
    </Layout>
  </b-overlay>
</template>

<style scoped lang="scss">
.accounts-page {
  &__no-accounts {
    padding: 16px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__table-container {
    overflow: auto;
    padding-bottom: 30px;
  }
}

.btn-header-action {
  border-radius: 4px;
  border: none;
  margin-left: 5px;

  &.active {
    background: var(--bs-brand);
  }
}

table {
  border-collapse: separate;
  border-spacing: 0 16px;
  margin-top: -16px;

  thead {
    tr {
      background: #ffffff;
      box-shadow: 0 0 8px rgba(18, 38, 63, 0.06);
    }

    th {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #b9b9c3;
    }
  }
}
</style>
