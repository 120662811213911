<script>
export default {
  name: 'CurrencyIcon',
  props: {
    size: {
      type: Number,
      default: 56,
    },
    symbol: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div :style="{ minWidth: `${size}px`, height: `${size}px`, width: 'fit-content' }">
    <span
      class="avatar-title bg-soft bg-success text-success"
      :style="{
        fontSize: symbol.length > 1 ? `100%` : `${size / 2}px`,
        padding: symbol.length > 1 ? '5px' : 0,
        borderRadius: symbol.length > 3 ? '12px' : '50%',
      }"
    >
      {{ symbol }}
    </span>
  </div>
</template>
