<script>
export default {
  name: "btc",
  props: {
    size: {
      type: Number,
      default: 56,
    },
  },
};
</script>

<template>
  <div
    :style="{ minWidth: `${size}px`, width: `${size}px`, height: `${size}px` }"
  >
    <span
      class="avatar-title rounded-circle bg-soft bg-warning text-warning"
      :style="{ fontSize: `${size / 2}px` }"
    >
      <i class="mdi mdi-bitcoin"></i>
    </span>
  </div>
</template>
