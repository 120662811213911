import Swal from "sweetalert2";
import { copyText } from 'vue3-clipboard';

export default {
    methods: {
        copyToClipboard(text) {
            copyText(text, undefined, (error) => {
                if (error) {
                    Swal.fire(text);
                } else {
                    this.$notify({
                        type: 'success',
                        text: this.$t("Common.Clipboard.Success"),
                    });
                }
            })
        },
    }
}
